import React from "react";
import Form from "react-bootstrap/Form";
import api from "../../../api";
import { ConsentModal } from "../../../components/ConsentModal";
import { LoadingButton } from "../../../components/LoadingButton";

export interface DeleteTabProps {
  tabNames: string[];
  update: () => void;
  onError: (message: string) => void;
}

export const DeleteTab: React.FunctionComponent<DeleteTabProps> = (props: DeleteTabProps) => {
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState("");

  const onDeleteTabButtonClicked = () => {
    setShow(false);
    setIsDeleting(true);
    api
      .delete("/thompsonaccounting/tabs", { data: { tabName: selectedTab } })
      .then((response) => props.update())
      .catch((error) => props.onError(error.response.data.message))
      .finally(() => setIsDeleting(false));
  };

  return (
    <>
      <Form className="mt-5">
        <h3>Delete Tab</h3>
        <Form.Group className="mb-3">
          <Form.Label>Select the tab to delete</Form.Label>
          <Form.Select onChange={(e) => setSelectedTab(e.target.value)}>
            {props.tabNames.map((name, index) => {
              return <option key={`tab-name-${index}`}>{name}</option>;
            })}
          </Form.Select>
        </Form.Group>

        <LoadingButton isLoading={isDeleting} onClick={() => setShow(true)}>
          Delete tab
        </LoadingButton>
      </Form>

      <ConsentModal
        show={show}
        title={`Delete ${selectedTab}?`}
        subtitle={
          "Deleting this tab will also delete all of the fields in this tab for every client. Are you sure you want to delete the tab?"
        }
        denyText={"Never mind"}
        deny={() => setShow(false)}
        acceptText={"Delete tab"}
        accept={onDeleteTabButtonClicked}
      />
    </>
  );
};
