import React from "react";
import Button from "react-bootstrap/Button";
import "./RouteTab.css";

export interface RouteTabProps {
  text: string;
  route: string;
  activeRoute: string;
  onRouteButtonClicked: (route: string) => void;
}

export const RouteTab: React.FunctionComponent<RouteTabProps> = (props: RouteTabProps) => {
  return (
    <Button
      variant={`${props.activeRoute === props.route ? "success" : "text"}`}
      onClick={() => props.onRouteButtonClicked(props.route)}
    >
      {props.text}
    </Button>
  );
};
