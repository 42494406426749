import React from "react";
import { useNavigate } from "react-router";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { ConsentModal } from "../../../components/ConsentModal";
import { ClientDetailsTab } from "../../../components/ClientDetailsTabs";
import { LocalStorageKey } from "../../../types";
import { ClientDetails } from "../../../types/ClientDetails";
import { deleteFromLocalStorage, readFromLocalStorage } from "../../../utils/localStorage";
import api from "../../../api";
import { parseQueryParams } from "./EditClient.utils";

export interface EditClientProps {}

export const EditClient: React.FunctionComponent<EditClientProps> = (props: EditClientProps) => {
  const navigate = useNavigate();

  const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false);
  // Use a ref to access state in an event listener.
  const hasUnsavedChangesRef = React.useRef(hasUnsavedChanges);
  const [showUnsavedChangeModal, setShowUnsavedChangeModal] = React.useState(false);
  const [showSaveConfirmationModal, setShowSaveConfirmationModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [clientDetails, setClientDetails] = React.useState<ClientDetails | undefined>(undefined);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleBackButton = () => {
    if (hasUnsavedChangesRef.current) {
      // Show the modal if there are unsaved changes.
      setShowUnsavedChangeModal(true);
      window.history.pushState("are-you-sure", document.title, window.location.href);
    } else {
      // Go back if there are no changes.
      navigate("/clients");
    }
  };

  const queryProps = parseQueryParams(window.location.search);

  React.useEffect(() => {
    // Push temp state to stop back button click from navigating.
    window.history.pushState("are-you-sure", document.title, window.location.href);
    window.addEventListener("popstate", handleBackButton);

    // This return method is called when the component unmounts.
    return () => {
      // Remove the state or multiple event listeners will get stacked over time.
      window.removeEventListener("popstate", handleBackButton);
      // Remove the temporary state if it exists.
      if (window.history.state === "are-you-sure") {
        window.history.back();
      }
    };
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    let user = readFromLocalStorage(LocalStorageKey.SelectedUser);
    deleteFromLocalStorage(LocalStorageKey.SelectedUser);
    if (user) {
      setClientDetails(JSON.parse(user));
    } else {
      navigate("/clients");
      return;
    }
  }, [navigate]);

  const onChange = (tabIndex: number, fieldIndex: number, value: string | boolean): void => {
    if (clientDetails === undefined) {
      return;
    }

    hasUnsavedChangesRef.current = true;
    setHasUnsavedChanges(true);

    var copy = { ...clientDetails };
    copy.tabs[tabIndex].fields[fieldIndex].value = value;
    setClientDetails(copy);
  };

  const onBackButtonClicked = (overrideSavedChangesCheck?: boolean) => {
    if (!overrideSavedChangesCheck && hasUnsavedChanges) {
      setShowUnsavedChangeModal(true);
    } else {
      navigate("/clients");
    }
  };

  const onSubmitButtonClicked = () => {
    setIsSubmitting(true);
    api
      .put("/thompsonaccounting/clients", { clientDetails: clientDetails })
      .then((response) => {
        setHasUnsavedChanges(false);
        navigate("/clients");
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      })
      .finally(() => setIsSubmitting(false));
  };

  const onDeleteButtonClicked = () => {
    api
      .delete("/thompsonaccounting/clients", { data: { clientId: clientDetails?.id } })
      .then((response) => {
        navigate("/clients");
      })
      .catch((error) => setErrorMessage(error.response.data.message));
  };

  return clientDetails !== undefined ? (
    <Stack direction="vertical" style={{ width: "90%", alignItems: "center" }}>
      <Row style={{ display: "flex", justifyContent: "start", width: "90%", margin: "10px 0 0" }}>
        <div
          style={{ color: "#0d6efd", cursor: "pointer", fontWeight: "bold", fontSize: "20px" }}
          onClick={() => onBackButtonClicked(false)}
        >
          &lt; Back
        </div>
      </Row>
      <Row
        style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "90%", margin: "0 0 10px" }}
      >
        <Col xs={10}>
          <h3 style={{ width: "100%", margin: "0" }}>{clientDetails.tabs[0].fields[0].value}</h3>
        </Col>
        <Col xs={2}>
          <Button className="mt-3 mb-3" variant="primary" onClick={() => setShowSaveConfirmationModal(true)}>
            Submit
          </Button>
        </Col>
      </Row>
      {errorMessage && (
        <Row style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Alert variant="danger" className="mt-3" style={{ width: "80%" }}>
            <Alert.Heading>Something's happened</Alert.Heading>
            <p>{errorMessage}</p>
          </Alert>
        </Row>
      )}
      {isSubmitting && (
        <div className="loading-overlay">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <ClientDetailsTab formData={clientDetails} defaultTabName={queryProps.defaultTab} onDbColChange={onChange} />
      <Stack
        direction="horizontal"
        className="mb-3"
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <Row style={{ width: "80%" }}>
          <Button variant="primary" style={{ width: "100px" }} onClick={() => setShowDeleteModal(true)}>
            Delete
          </Button>
        </Row>
      </Stack>

      <ConsentModal
        show={showUnsavedChangeModal}
        title={"You have unsaved changes"}
        subtitle={"Are you sure you want to go back? Your changes will not be saved."}
        denyText={"I don't want to save"}
        deny={() => navigate("/clients")}
        acceptText={"I want to save my changes"}
        accept={() => setShowUnsavedChangeModal(false)}
      />
      <ConsentModal
        show={showSaveConfirmationModal}
        title={"Are you sure you want to save?"}
        subtitle={"Please confirm you want to update the client's information. Previous changes will be lost."}
        denyText={"Do not save"}
        deny={() => onBackButtonClicked(true)}
        acceptText={"Save changes"}
        accept={onSubmitButtonClicked}
      />
      <ConsentModal
        show={showDeleteModal}
        title={"Do you really want to delete this client?"}
        subtitle={"Are you sure you want to delete this client? All their data will be permanently deleted."}
        denyText={"Don't delete"}
        deny={() => setShowDeleteModal(false)}
        acceptText={"I want to delete"}
        accept={onDeleteButtonClicked}
      />
    </Stack>
  ) : (
    <div>User could not be parsed.</div>
  );
};
