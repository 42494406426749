import { ClientDetails } from "../../types/ClientDetails";

export enum SearchOperation {
  Equals,
  DoesNotEqual,
  Contains,
}

export type SearchProps = {
  field: string;
  value: string;
  operation: SearchOperation;
};

export const parseSearchString = (searchString: string): SearchProps[] => {
  // First, split on spacing character.
  let searchTerms = searchString.split(";");
  searchTerms = searchTerms.map((t) => t.trimStart().trimEnd());

  // Then, split each term on the various search characters.
  const searchProps: SearchProps[] = [];
  for (const t of searchTerms) {
    if (t.includes(":")) {
      // Contains
      const parts = t.split(":");
      searchProps.push({
        field: parts[0],
        value: parts[1],
        operation: SearchOperation.Contains,
      });
    } else if (t.includes("=")) {
      // Equals
      const parts = t.split("=");
      searchProps.push({
        field: parts[0],
        value: parts[1],
        operation: SearchOperation.Equals,
      });
    } else if (t.includes("!")) {
      // Does not equal
      const parts = t.split("!");
      searchProps.push({
        field: parts[0],
        value: parts[1],
        operation: SearchOperation.DoesNotEqual,
      });
    } else if (t !== "") {
      // Search any field.
      searchProps.push({
        field: "*",
        value: t,
        operation: SearchOperation.Contains,
      });
    }
  }

  return searchProps;
};

const resolveSearch = (searchProp: SearchProps, clientField: string, fieldValue: string | boolean): boolean => {
  if (searchProp.field !== "*" && clientField.toLowerCase() !== searchProp.field.toLowerCase()) {
    return false;
  }

  if (typeof fieldValue === "string") {
    const clientValue = fieldValue.toLowerCase();
    const searchValue = searchProp.value.toLowerCase();
    switch (searchProp.operation) {
      case SearchOperation.Contains:
        return clientValue.includes(searchValue);
      case SearchOperation.Equals:
        return clientValue === searchValue;
      case SearchOperation.DoesNotEqual:
        return clientValue !== searchValue;
    }
  } else {
    const shownValue = fieldValue ? "yes" : "no";

    switch (searchProp.operation) {
      case SearchOperation.Contains:
        return shownValue.includes(searchProp.value);
      case SearchOperation.Equals:
        return shownValue === searchProp.value;
      case SearchOperation.DoesNotEqual:
        return shownValue !== searchProp.value;
    }
  }
};

export const performSearch = (clients: ClientDetails[], searchProps: SearchProps[]): ClientDetails[] => {
  let filteredClients: ClientDetails[] = [];
  let matchedFields = new Set<string>();
  // Always include the first term by default.
  matchedFields.add(clients[0].tabs[0].fields[0].label);

  for (const client of clients) {
    let matchedClient = false;

    for (const tab of client.tabs) {
      for (const field of tab.fields) {
        for (const searchProp of searchProps) {
          if (resolveSearch(searchProp, field.label, field.value)) {
            matchedClient = true;
            field.searchedMatched = true;
            matchedFields.add(field.label);
          }
        }
      }
    }

    if (matchedClient) {
      filteredClients.push(client);
    }
  }

  for (const client of filteredClients) {
    for (const tab of client.tabs) {
      tab.fields = tab.fields.filter((field) => matchedFields.has(field.label));
    }
  }

  return filteredClients;
};
