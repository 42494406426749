import React from "react";
import Form from "react-bootstrap/Form";
import { DatabaseColumn } from "../../types/ClientDetails";

interface DbColProps {
  tabIndex: number;
  fieldIndex: number;
  columnData: DatabaseColumn;
  onChange: (tabIndex: number, fieldIndex: number, event: string | boolean) => void;
}

export const DbCol: React.FunctionComponent<DbColProps> = (props: DbColProps) => {
  const tabIndex = props.tabIndex;
  const fieldIndex = props.fieldIndex;
  const column = props.columnData;

  if (column.type === "text") {
    return (
      <Form.Group className="mb-3" key={`form-col-${tabIndex}-${fieldIndex}`}>
        <Form.Label>{`${column.label}${column.required ? "*" : ""}`}</Form.Label>
        <Form.Control
          defaultValue={column.value ? column.value.toString() : ""}
          onChange={(e) => props.onChange(tabIndex, fieldIndex, e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
        ></Form.Control>
      </Form.Group>
    );
  } else if (column.type === "checkbox") {
    return (
      <Form.Group className="mb-3" key={`form-col-${tabIndex}-${fieldIndex}`}>
        <Form.Check
          checked={column.value.toString() === "true"}
          type="checkbox"
          label={`${column.label}${column.required ? "*" : ""}`}
          onChange={(e) => props.onChange(tabIndex, fieldIndex, e.target.checked)}
        ></Form.Check>
      </Form.Group>
    );
  } else if (column.type === "select" && column.options) {
    return (
      <Form.Group className="mb-3" key={`form-col-${tabIndex}-${fieldIndex}`}>
        <Form.Label>{`${column.label}${column.required ? "*" : ""}`}</Form.Label>
        <Form.Select
          defaultValue={column.value ? column.value.toString() : "---"}
          onChange={(e) => props.onChange(tabIndex, fieldIndex, e.target.value)}
        >
          {column.options.map((option, index) => {
            return <option key={`select-${column.label}-${index}`}>{option}</option>;
          })}
        </Form.Select>
      </Form.Group>
    );
  } else if (column.type === "header") {
    return (
      <h4 className="mb-3" key={`form-col-${tabIndex}-${fieldIndex}`}>
        {column.label}
      </h4>
    );
  } else {
    return <></>;
  }
};
