import React from "react";
import Button from "react-bootstrap/Button";
import { Card } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import api from "../../api";
import { hashString } from "../../utils/hash";
import { writeToLocalStorage } from "../../utils/localStorage";
import { LocalStorageKey } from "../../types";
import { useDispatch } from "react-redux";
import { setLoginStatus } from "../../store/slices/login";
import { UserStatus } from "../../store/types";
import { setUserStatus } from "../../store/slices/login";
import { useNavigate } from "react-router-dom";

interface LoginProps {}

export const Login: React.FunctionComponent<LoginProps> = (props: LoginProps) => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmitButtonClicked = () => {
    var hashedPassword = hashString(password);
    api
      .post("/thompsonaccounting/login", {
        username: username,
        password: hashedPassword,
      })
      .then((response) => {
        if (!response.data.token) {
          dispatch(setLoginStatus(false));
          dispatch(setUserStatus(UserStatus.Unknown));
          return;
        }

        var token = response.data.token;
        var userStatus = response.data.isadmin ? UserStatus.Admin : UserStatus.Unknown;
        writeToLocalStorage(LocalStorageKey.AuthToken, token);
        writeToLocalStorage(LocalStorageKey.UserStatus, userStatus);
        dispatch(setLoginStatus(true));
        dispatch(setUserStatus(userStatus));
        navigate("/clients");
      })
      .catch((error) => console.log(error));
  };

  return (
    <Card>
      <Card.Body>
        <h2>Login</h2>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              onChange={(e) => setUsername(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter password"
              onChange={(e) => setPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Button variant="primary" onClick={onSubmitButtonClicked}>
            Submit
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};
