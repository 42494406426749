import { LoginState } from "../slices/login";

export enum UserStatus {
  Unknown = "Unknown",
  User = "User",
  Admin = "Admin",
}

export interface AccountingStore {
  login: LoginState;
}
