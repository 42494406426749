import React from "react";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import api from "../../../api";
import { DatabaseColumn } from "../../../types/ClientDetails";
import { LoadingButton } from "../../../components/LoadingButton";

export interface EditFieldProps {
  tabNames: string[];
  update: () => void;
  onError: (message: string) => void;
}

export const EditField: React.FunctionComponent<EditFieldProps> = (props: EditFieldProps) => {
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [isLoadingFieldNames, setIsLoadingFieldNames] = React.useState(false);
  const [isLoadingFieldSchema, setIsLoadingFieldSchema] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState(props.tabNames.length > 0 ? props.tabNames[0] : "");
  const [fieldNames, setFieldNames] = React.useState<string[]>([]);
  const [column, setColumn] = React.useState<DatabaseColumn | undefined>(undefined);
  const [selectedField, setSelectedField] = React.useState("");
  const [isDropdown, setIsDropdown] = React.useState(false);
  const [possibleValueState, setPossibleValueState] = React.useState("");

  React.useEffect(() => {
    const { onError } = { ...props };
    setIsLoadingFieldNames(true);
    api
      .get(`/thompsonaccounting/field?tabName=${selectedTab}`)
      .then((response) => {
        if (response.data.fieldNames) {
          setFieldNames(response.data.fieldNames);
          setSelectedField(response.data.fieldNames[0]);
        }
        setIsLoadingFieldNames(false);
      })
      .catch((error) => {
        onError(error.response.data.message);
      });
  }, [selectedTab, props]);

  React.useEffect(() => {
    if (!selectedTab || !selectedField) {
      return;
    }

    setIsLoadingFieldSchema(true);
    api
      .get(`/thompsonaccounting/field/schema?tabName=${selectedTab}&fieldName=${selectedField}`)
      .then((response) => {
        let column = response.data.fieldSchema as DatabaseColumn;
        setColumn(column);
        setIsDropdown(column.type === "select");
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoadingFieldSchema(false));
  }, [selectedTab, selectedField]);

  const onSelectedTabChange = (tab: string) => {
    setSelectedField("");
    setSelectedTab(tab);
  };

  const onPossibleValueChange = (value: string) => {
    setPossibleValueState(value);
  };

  const addValueButtonClicked = () => {
    let copy: DatabaseColumn = { ...column } as DatabaseColumn;

    if (!copy.options) {
      copy.options = [];
    }

    copy.options.push(possibleValueState);
    setColumn(copy);
    setPossibleValueState("");
  };

  const onUpdateButtonClicked = () => {
    setIsUpdating(true);
    api
      .put("/thompsonaccounting/field", {
        tabName: selectedTab,
        fieldName: selectedField,
        fieldValues: column,
      })
      .then((response) => props.update && props.update())
      .catch((error) => props.onError && props.onError(error.response.data.message))
      .finally(() => setIsUpdating(false));
  };

  return (
    <Form className="mt-5">
      <h3>Edit Fields</h3>
      <Form.Group className="mb-3">
        <Form.Label>Select the tab for the field</Form.Label>
        <Form.Select onChange={(e) => onSelectedTabChange(e.target.value)}>
          {props.tabNames.map((name, index) => {
            return <option key={`tab-name-${index}`}>{name}</option>;
          })}
        </Form.Select>
      </Form.Group>

      {isLoadingFieldNames ? (
        <div className="mb-3" style={{ height: "70px", display: "flex", alignItems: "center" }}>
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Form.Group className="mb-3">
          <Form.Label>Select the field in the tab to edit</Form.Label>
          <Form.Select onChange={(e) => setSelectedField(e.target.value)}>
            {fieldNames.map((name, index) => {
              return <option key={`field-name-${index}`}>{name}</option>;
            })}
          </Form.Select>
        </Form.Group>
      )}

      {isLoadingFieldSchema ? (
        <div className="mb-3" style={{ height: "70px", display: "flex", alignItems: "center" }}>
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        column && (
          <>
            <Form.Group className="mb-3">
              <Form.Label>Name of the field</Form.Label>
              <Form.Control
                type="text"
                value={column.label}
                onChange={(e) => {
                  let copy = { ...column } as DatabaseColumn;
                  copy.label = e.target.value;
                  setColumn(copy);
                }}
                onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
              ></Form.Control>
            </Form.Group>

            {isDropdown && column.options && (
              <Form.Group className="mb-3">
                <Form.Label>Possible Values</Form.Label>
                <Form.Control
                  type="text"
                  value={possibleValueState}
                  onChange={(e) => onPossibleValueChange(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      addValueButtonClicked();
                    }
                  }}
                ></Form.Control>
                {column.options.map((value, index) => {
                  return (
                    <Row key={`possible-value-${index}`}>
                      <p style={{ marginBottom: "0" }} key={`possible-value-${index}`}>
                        {value}
                      </p>
                    </Row>
                  );
                })}
                <Button className="mt-3" variant="outline-primary" onClick={addValueButtonClicked}>
                  Add possible value
                </Button>
              </Form.Group>
            )}

            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                label="Required?"
                checked={column.required}
                onChange={(e) => {
                  let copy = { ...column };
                  copy.required = e.target.checked;
                  setColumn(copy);
                }}
              ></Form.Check>
            </Form.Group>

            <Form.Group className="mb-3">
              <LoadingButton isLoading={isUpdating} onClick={onUpdateButtonClicked}>
                Update field
              </LoadingButton>
            </Form.Group>
          </>
        )
      )}
    </Form>
  );
};
