import React from "react";
import Form from "react-bootstrap/Form";
import api from "../../../api";
import { LoadingButton } from "../../../components/LoadingButton";

export interface AddTabProps {
  update: () => void;
  onError: (message: string) => void;
}

export const AddTab: React.FunctionComponent<AddTabProps> = (props: AddTabProps) => {
  const [isCreating, setIsCreating] = React.useState(false);
  const [addTabName, setAddTabName] = React.useState("");

  const onAddTabNameChanged = (value: string) => {
    setAddTabName(value);
  };

  const onAddTabButtonClicked = () => {
    setIsCreating(true);
    api
      .post("/thompsonaccounting/tabs", { tabName: addTabName })
      .then((response) => {
        props.update();
        onAddTabNameChanged("");
      })
      .catch((error) => props.onError(error.response.data.message))
      .finally(() => setIsCreating(false));
  };

  return (
    <Form className="mt-5">
      <h3>Add Tabs</h3>
      <Form.Group className="mb-3">
        <Form.Label>Name of tab</Form.Label>
        <Form.Control
          type="text"
          onChange={(e) => onAddTabNameChanged(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
        ></Form.Control>
      </Form.Group>

      <Form.Group className="mb-3">
        <LoadingButton isLoading={isCreating} onClick={onAddTabButtonClicked}>
          Add tab
        </LoadingButton>
      </Form.Group>
    </Form>
  );
};
