import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

interface ConsentModalProps {
  show: boolean;
  title: string;
  subtitle: string;
  denyText: string;
  deny: () => void;
  acceptText: string;
  accept: () => void;
}

export const ConsentModal: React.FunctionComponent<ConsentModalProps> = (props: ConsentModalProps) => {
  return (
    <Modal show={props.show} onHide={() => props.deny && props.deny()}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.subtitle}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.deny && props.deny()}>
          {props.denyText}
        </Button>
        <Button variant="primary" onClick={() => props.accept && props.accept()}>
          {props.acceptText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
