import React from "react";
import Table from "react-bootstrap/Table";
import { ClientDetails } from "../../types/ClientDetails";
import "./ClientTable.css";
import { Clone } from "../../utils/types";

export interface ClientTableProps {
  clients: ClientDetails[];
  onClientClicked: (index: number, startingTabName: string) => void;
}

export const ClientTable: React.FunctionComponent<ClientTableProps> = (props: ClientTableProps) => {
  const [clientsData, setClientsData] = React.useState<ClientDetails[]>([]);
  const [sortHeader, setSortHeader] = React.useState("");
  const [direction, setDirection] = React.useState(false);

  React.useEffect(() => {
    setClientsData(props.clients);
  }, [props.clients]);

  const sortByHeader = (header: string) => {
    if (header !== sortHeader) {
      // Set the new header we are filtering on and reset the direction.
      setSortHeader(header);
      setDirection(true);
    } else {
      // Flip which way we are sorting.
      setDirection(!direction);
    }

    let copy = Clone.deepCopy(props.clients);
    copy.sort((a, b) => {
      for (let tabIndex = 0; tabIndex < a.tabs.length; tabIndex++) {
        for (let fieldIndex = 0; fieldIndex < a.tabs[tabIndex].fields.length; fieldIndex++) {
          if (
            a.tabs[tabIndex].fields[fieldIndex].name === header &&
            b.tabs[tabIndex].fields[fieldIndex].name === header
          ) {
            let nameA = a.tabs[tabIndex].fields[fieldIndex].value.toString().toLowerCase();
            let nameB = b.tabs[tabIndex].fields[fieldIndex].value.toString().toLowerCase();
            if (nameA < nameB) {
              return direction ? 1 : -1;
            }

            if (nameA > nameB) {
              return direction ? -1 : 1;
            }

            return 0;
          }
        }
      }

      return 0;
    });
    setClientsData(copy);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <h3>Your Clients</h3>
      {clientsData.length > 0 ? (
        <Table responsive striped>
          <thead>
            <tr>
              {clientsData[0].tabs.map((tab, tabIndex) => {
                return tab.fields.map((field, fieldIndex) => {
                  return (
                    <th key={`table-header-${tabIndex}-${fieldIndex}`} onClick={() => sortByHeader(field.name)}>
                      {field.label}
                    </th>
                  );
                });
              })}
            </tr>
          </thead>
          <tbody>
            {clientsData.map((clientDetails, index) => {
              return (
                <tr key={`client-details-row-${index}`}>
                  {clientDetails.tabs.map((tab, tabIndex) => {
                    return tab.fields.map((field, fieldIndex) => {
                      let value: string = "";
                      if (typeof field.value === "boolean") {
                        if (field.value) {
                          value = "Yes";
                        } else {
                          value = "No";
                        }
                      } else {
                        if (field.value) {
                          value = field.value;
                        }
                      }
                      return (
                        <td
                          key={`table-cell=${index}-${tabIndex}-${fieldIndex}}`}
                          className={field.searchedMatched === true ? "table-cell-highlight" : ""}
                          onClick={() => props.onClientClicked(index, tab.name)}
                        >
                          {value}
                        </td>
                      );
                    });
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <Table>
          <tbody>
            <tr>
              <td>You have no clients</td>
            </tr>
          </tbody>
        </Table>
      )}
    </div>
  );
};
