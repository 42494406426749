import React from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Alert from "react-bootstrap/Alert";
import Stack from "react-bootstrap/Stack";
import Spinner from "react-bootstrap/Spinner";
import { AddTab, AddField, EditTab, EditField, DeleteTab, DeleteField, OrderField, MoveField } from "./editors";
import api from "../../api";

export interface SchemaEditorProps {}

export const SchemaEditor: React.FunctionComponent<SchemaEditorProps> = (props: SchemaEditorProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isTabsLoading, setIsTabsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [tabNames, setTabNames] = React.useState<string[]>([]);

  React.useEffect(() => {
    setIsLoading(!isTabsLoading ? false : true);
  }, [isTabsLoading]);

  const getTabData = () => {
    setIsTabsLoading(true);
    api
      .get("/thompsonaccounting/tabs")
      .then((response) => {
        setTabNames(response.data.tabs);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      })
      .finally(() => setIsTabsLoading(false));
  };

  React.useEffect(() => {
    getTabData();
  }, []);

  const handleChildTabUpdate = () => {
    getTabData();
  };

  const handleChildError = (message: string) => {
    setErrorMessage(message);
  };

  if (isLoading) {
    return (
      <Spinner animation="border" role="status" variant="primary">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Stack direction="vertical" style={{ maxWidth: "80%" }}>
        {errorMessage && (
          <Alert variant="danger" className="mt-3">
            <Alert.Heading>Something's happened</Alert.Heading>
            <p>{errorMessage}</p>
          </Alert>
        )}
        <Tab.Container defaultActiveKey="AddTab">
          <Row>
            <Col sm={3} className="mt-3">
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="AddTab">Add Tabs</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="AddField">Add Fields</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="EditTab">Edit Tab Names</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="EditField">Edit Fields</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="DeleteTab">Delete Tab</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="DeleteField">Delete Field</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="OrderField">Order Fields</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="MoveField">Move Field</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>

            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="AddTab">
                  <AddTab update={handleChildTabUpdate} onError={handleChildError} />
                </Tab.Pane>
                <Tab.Pane eventKey="AddField">
                  <AddField tabNames={tabNames} update={handleChildTabUpdate} onError={handleChildError} />
                </Tab.Pane>
                <Tab.Pane eventKey="EditTab">
                  <EditTab tabNames={tabNames} update={handleChildTabUpdate} onError={handleChildError} />
                </Tab.Pane>
                <Tab.Pane eventKey="EditField">
                  <EditField tabNames={tabNames} update={handleChildTabUpdate} onError={handleChildError} />
                </Tab.Pane>
                <Tab.Pane eventKey="DeleteTab">
                  <DeleteTab tabNames={tabNames} update={handleChildTabUpdate} onError={handleChildError} />
                </Tab.Pane>
                <Tab.Pane eventKey="DeleteField">
                  <DeleteField tabNames={tabNames} onError={handleChildError} />
                </Tab.Pane>
                <Tab.Pane eventKey="OrderField">
                  <OrderField tabNames={tabNames} update={handleChildTabUpdate} onError={handleChildError} />
                </Tab.Pane>
                <Tab.Pane eventKey="MoveField">
                  <MoveField tabNames={tabNames} update={handleChildTabUpdate} onError={handleChildError} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Stack>
    );
  }
};

export default SchemaEditor;
