import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { UserStatus } from "../types";
import { readFromLocalStorage } from "../../utils/localStorage";
import { LocalStorageKey } from "../../types";

export interface LoginState {
  isLoggedIn: boolean;
  userStatus: UserStatus;
}

const initialState: LoginState = {
  isLoggedIn: !!readFromLocalStorage(LocalStorageKey.AuthToken),
  userStatus: readFromLocalStorage(LocalStorageKey.UserStatus) as UserStatus,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setLoginStatus: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setUserStatus: (state, action: PayloadAction<UserStatus>) => {
      state.userStatus = action.payload;
    },
  },
});

export const { setLoginStatus, setUserStatus } = loginSlice.actions;

export default loginSlice.reducer;
