import React from "react";
import { useNavigate } from "react-router";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import api from "../../api";
import { ClientTable } from "../../components/ClientTable";
import { ClientDetails } from "../../types/ClientDetails";
import { writeToLocalStorage } from "../../utils/localStorage";
import { LocalStorageKey } from "../../types";
import { Clone } from "../../utils/types";
import { ClientSearch } from "../../components/ClientSearch";
import { parseSearchString, performSearch } from "./Clients.utils";

export interface ClientsProps {}

export const Clients: React.FunctionComponent<ClientsProps> = (props: ClientsProps) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(false);
  const [clientsData, setClientsData] = React.useState<ClientDetails[]>([]);
  const [clientsDataBackup, setClientsDataBackup] = React.useState<ClientDetails[]>([]);
  const [errorMessage, setErrorMessage] = React.useState("");

  React.useEffect(() => {
    setIsLoading(true);
    api
      .get("/thompsonaccounting/clients")
      .then((response) => {
        setClientsData(Clone.deepCopy(response.data));
        setClientsDataBackup(Clone.deepCopy(response.data));
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const onSearchButtonClicked = (searchData: string) => {
    const searchProps = parseSearchString(searchData);
    if (searchProps.length > 0 && clientsDataBackup.length > 0) {
      const filterableClients = Clone.deepCopy(clientsDataBackup);
      const searchResults = performSearch(filterableClients, searchProps);
      setClientsData(searchResults);
    } else {
      setClientsData(clientsDataBackup);
    }
  };

  const onClientClicked = (index: number, startingTabName: string) => {
    const selectedUser = clientsDataBackup.filter((d) => d.id === clientsData[index].id)[0];
    writeToLocalStorage(LocalStorageKey.SelectedUser, JSON.stringify(selectedUser));
    navigate(`/clients/edit?defaultTab=${startingTabName}`);
  };

  if (errorMessage) {
    return (
      <Alert variant="danger">
        <Alert.Heading>Something's happened</Alert.Heading>
        <p>{errorMessage}</p>
      </Alert>
    );
  } else if (isLoading) {
    return (
      <Spinner animation="border" role="status" variant="primary">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <div style={{ display: "flex", flexDirection: "column", width: "90%" }}>
        <ClientSearch onSearchButtonClicked={onSearchButtonClicked} />
        <ClientTable clients={clientsData} onClientClicked={onClientClicked} />
      </div>
    );
  }
};
