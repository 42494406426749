import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { store } from "../store";
import { setLoginStatus, setUserStatus } from "../store/slices/login";
import { UserStatus } from "../store/types";
import { LocalStorageKey } from "../types";
import { deleteFromLocalStorage, readFromLocalStorage } from "../utils/localStorage";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
  timeout: 60000,
});

instance.interceptors.request.use(
  (req: AxiosRequestConfig) => {
    // Set the appid and clientid fields before sending the request.
    if (req.headers === undefined) {
      req.headers = {};
    }

    const token = readFromLocalStorage(LocalStorageKey.AuthToken);
    req.headers["Authorization"] = `Bearer ${token}`;
    return req;
  },
  (err) => {
    Promise.reject(err);
  }
);

instance.interceptors.response.use(
  (res: AxiosResponse<any>) => {
    return res;
  },
  (err) => {
    if (err.response.status === 401) {
      store.dispatch(setLoginStatus(false));
      store.dispatch(setUserStatus(UserStatus.Unknown));
      deleteFromLocalStorage(LocalStorageKey.AuthToken);
      deleteFromLocalStorage(LocalStorageKey.UserStatus);
      window.location.href = `/logout?reason=${err.response.data.reason}`;
    }
    return Promise.reject(err);
  }
);

export default instance;
