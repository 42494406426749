import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import Spinner from "react-bootstrap/Spinner";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import api from "../../../api";
import { FieldMetadata } from "../../../types/ClientDetails";
import "./OrderField.css";
import { LoadingButton } from "../../../components/LoadingButton";

interface OrderFieldProps {
  tabNames: string[];
  update: () => void;
  onError: (message: string) => void;
}

export const OrderField: React.FunctionComponent<OrderFieldProps> = (props: OrderFieldProps) => {
  const [isLoadingMetadata, setIsLoadingMetadata] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState(props.tabNames.length > 0 ? props.tabNames[0] : "");
  const [fieldMetadata, setFieldMetadata] = React.useState<FieldMetadata[]>([]);

  React.useEffect(() => {
    setIsLoadingMetadata(true);
    api
      .get(`/thompsonaccounting/fields/metadata?tabName=${selectedTab}`)
      .then((response) => setFieldMetadata(response.data))
      .catch((error) => console.log(error))
      .finally(() => setIsLoadingMetadata(false));
  }, [selectedTab]);

  const array_move = (arr: FieldMetadata[], old_index: number, new_index: number) => {
    let copy = [...arr];
    copy.splice(new_index, 0, copy.splice(old_index, 1)[0]);
    copy[new_index].position = new_index;
    copy[old_index].position = old_index;
    setFieldMetadata(copy);
  };

  const onUpArrowClicked = (index: number) => {
    if (index === 0) {
      return;
    }
    array_move(fieldMetadata, index, index - 1);
  };

  const onDownArrowClicked = (index: number) => {
    if (index === fieldMetadata.length - 1) {
      return;
    }

    array_move(fieldMetadata, index, index + 1);
  };

  const onSubmitButtonClicked = () => {
    setIsSubmitting(true);
    api
      .post("/thompsonaccounting/fields/metadata", {
        tabName: selectedTab,
        fieldMetadata: fieldMetadata,
      })
      .then((response) => props.update && props.update())
      .catch((error) => props.onError && props.onError(error.response.data.message))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Form className="mt-5">
      <Row>
        <Col xs={9}>
          <h3>Order Fields</h3>
        </Col>
        <Col xs={3}>
          <LoadingButton isLoading={isSubmitting} onClick={onSubmitButtonClicked}>
            Submit
          </LoadingButton>
        </Col>
      </Row>
      <Form.Group className="mb-3">
        <Form.Label>Select a tab to redorder the fields.</Form.Label>

        <Form.Select onChange={(e) => setSelectedTab(e.target.value)}>
          {props.tabNames.map((name, index) => {
            return <option key={`tab-name-${index}`}>{name}</option>;
          })}
        </Form.Select>
      </Form.Group>

      {isLoadingMetadata ? (
        <Spinner className="mb-3" animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <Stack className="mb-3" direction="vertical">
          <span>Use the arrows to rearrange the positions of the fields.</span>
          {fieldMetadata.map((data, index) => {
            return (
              <Stack key={`field-reorder-item-${index}`} direction="horizontal">
                <div className="arrow-group">
                  <div className="move-arrow" onClick={() => onUpArrowClicked(index)}>
                    <FaArrowUp />
                  </div>
                  <div className="move-arrow" onClick={() => onDownArrowClicked(index)}>
                    <FaArrowDown />
                  </div>
                </div>
                <div>{data.field_name}</div>
              </Stack>
            );
          })}
        </Stack>
      )}
    </Form>
  );
};
