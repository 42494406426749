import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { ColumnType, DatabaseColumn } from "../../../types/ClientDetails";
import api from "../../../api";
import { LoadingButton } from "../../../components/LoadingButton";

export interface AddFieldProps {
  tabNames: string[];
  update: () => void;
  onError: (message: string) => void;
}

export const AddField: React.FunctionComponent<AddFieldProps> = (props: AddFieldProps) => {
  const [isCreating, setIsCreating] = React.useState(false);
  const [tabName, setTabName] = React.useState(props.tabNames.length > 0 ? props.tabNames[0] : "");
  const [fieldName, setFieldName] = React.useState("");
  const [fieldType, setFieldType] = React.useState<ColumnType>("text");
  const [isDropdown, setIsDropdown] = React.useState(false);
  const [possibleValueState, setPossibleValueState] = React.useState("");
  const [possibleValues, setPossibleValues] = React.useState<string[]>([]);
  const [isRequired, setIsRequired] = React.useState(false);

  const onFieldTypeChange = (value: ColumnType) => {
    setFieldType(value);
    setIsDropdown(value === "select");
  };

  const addValueButtonClicked = () => {
    let copy = [...possibleValues];
    copy.push(possibleValueState);
    setPossibleValues(copy);
    setPossibleValueState("");
  };

  const onAddFieldButtonClicked = () => {
    let fieldData: DatabaseColumn = {
      name: fieldName,
      label: fieldName,
      type: fieldType,
      value: "",
      required: isRequired,
      options: possibleValues,
    };
    setIsCreating(true);
    api
      .post("/thompsonaccounting/fields", { tabName: tabName, fieldData: fieldData })
      .then((response) => props.update && props.update())
      .catch((error) => props.onError(error.response.data.message))
      .finally(() => setIsCreating(false));
  };

  return (
    <Form className="mt-5">
      <h3>Add Fields</h3>
      <Form.Group className="mb-3">
        <Form.Label>Tab to add to</Form.Label>
        <Form.Select onChange={(e) => setTabName(e.target.value)}>
          {props.tabNames.map((name, index) => {
            return <option key={`tab-name=${index}`}>{name}</option>;
          })}
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Name of the field</Form.Label>
        <Form.Control
          type="text"
          onChange={(e) => setFieldName(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
        ></Form.Control>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Type of the field</Form.Label>
        <Form.Select onChange={(e) => onFieldTypeChange(e.target.value as ColumnType)}>
          <option value="text">Text</option>
          <option value="checkbox">Checkbox</option>
          <option value="select">Dropdown</option>
        </Form.Select>
      </Form.Group>

      {isDropdown && (
        <Form.Group className="mb-3">
          <Form.Label>Possible Values</Form.Label>
          <Form.Control
            type="text"
            value={possibleValueState}
            onChange={(e) => setPossibleValueState(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                addValueButtonClicked();
              }
            }}
          ></Form.Control>
          {possibleValues.map((value, index) => {
            return (
              <p style={{ marginBottom: "0" }} key={`possible-value-${index}`}>
                {value}
              </p>
            );
          })}
          <Button className="mt-3" variant="outline-primary" onClick={addValueButtonClicked}>
            Add possible value
          </Button>
        </Form.Group>
      )}

      <Form.Group className="mb-3">
        <Form.Check type="checkbox" label="Required?" onChange={(e) => setIsRequired(e.target.checked)}></Form.Check>
      </Form.Group>

      <Form.Group className="mb-3">
        <LoadingButton isLoading={isCreating} onClick={onAddFieldButtonClicked}>
          Add field
        </LoadingButton>
      </Form.Group>
    </Form>
  );
};
