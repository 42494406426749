import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import "./App.css";
import { useDispatch } from "react-redux";
import { deleteFromLocalStorage, readFromLocalStorage } from "./utils/localStorage";
import { LocalStorageKey } from "./types";
import { AccountingStore, UserStatus } from "./store/types";
import { setLoginStatus, setUserStatus } from "./store/slices/login";
import { useSelector } from "react-redux";
import { RouteTab } from "./components/RouteTab";

interface AppProps {}

const App: React.FunctionComponent<AppProps> = (props: AppProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state: AccountingStore) => state.login.isLoggedIn);
  let userStatus = useSelector((state: AccountingStore) => state.login.userStatus);

  const [isAdmin, setIsAdmin] = React.useState(false);
  const [activeRoute, setActiveRoute] = React.useState("");

  React.useEffect(() => {
    const authToken = readFromLocalStorage(LocalStorageKey.AuthToken);

    if (authToken) {
      dispatch(setLoginStatus(true));
    }

    if (userStatus) {
      dispatch(setUserStatus(userStatus));
      setIsAdmin(userStatus === UserStatus.Admin);
    }

    // Get the current route and use that to set the active route.
    const route = window.location.pathname;
    setActiveRoute(route);
  }, [dispatch, userStatus]);

  const onLoginButtonClicked = () => {
    navigate("/login");
  };

  const onLogoutButtonClicked = () => {
    dispatch(setLoginStatus(false));
    dispatch(setUserStatus(UserStatus.Unknown));
    deleteFromLocalStorage(LocalStorageKey.AuthToken);
    deleteFromLocalStorage(LocalStorageKey.UserStatus);
    setIsAdmin(false);
    navigate("/logout?reason=0");
  };

  const onRouteButtonClicked = (route: string) => {
    setActiveRoute(route);
    navigate(route);
  };

  return (
    <div className="App">
      <Stack className="toolbar" direction="horizontal" gap={3}>
        <RouteTab
          text="Clients"
          route="/clients"
          activeRoute={activeRoute}
          onRouteButtonClicked={onRouteButtonClicked}
        />
        <RouteTab
          text="New Client"
          route="/clients/new"
          activeRoute={activeRoute}
          onRouteButtonClicked={onRouteButtonClicked}
        />
        {isAdmin && (
          <RouteTab
            text="Client Details Editor"
            route="/schema-editor"
            activeRoute={activeRoute}
            onRouteButtonClicked={onRouteButtonClicked}
          />
        )}
        {!isLoggedIn ? (
          <Button variant="primary" onClick={onLoginButtonClicked}>
            Login
          </Button>
        ) : (
          <Button variant="primary" onClick={onLogoutButtonClicked}>
            Logout
          </Button>
        )}
      </Stack>
      <div className="window">
        <Outlet />
      </div>
    </div>
  );
};

export default App;
