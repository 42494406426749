import React from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { AccountingStore } from "../../store/types";

export interface HomeProps {}

export const Home: React.FunctionComponent<HomeProps> = (props: HomeProps) => {
  const navigate = useNavigate();

  const isLoggedIn = useSelector((state: AccountingStore) => state.login.isLoggedIn);

  React.useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    navigate("/clients");
  }, [navigate, isLoggedIn]);

  return (
    <div style={{ textAlign: "center" }}>
      <p>Welcome to the Client Management System for Thompson Accounting Company, PLLC!</p>
      <p>Please login to begin using the software.</p>
    </div>
  );
};
