import React from "react";
import { useNavigate } from "react-router";
import Alert from "react-bootstrap/Alert";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import api from "../../../api";
import { ClientDetails } from "../../../types/ClientDetails";
import { ClientDetailsTab } from "../../../components/ClientDetailsTabs";
import { ConsentModal } from "../../../components/ConsentModal";

export interface NewClientProps {}

export const NewClient: React.FunctionComponent<NewClientProps> = (props: NewClientProps) => {
  const navigate = useNavigate();

  const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false);
  // Use a ref to access state in an event listener.
  const hasUnsavedChangesRef = React.useRef(hasUnsavedChanges);
  const [showUnsavedChangeModal, setShowUnsavedChangeModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [formData, setFormData] = React.useState<ClientDetails>({ id: "", tabs: [] });

  React.useEffect(() => {
    setIsLoading(true);
    api
      .get("/thompsonaccounting/clients/new")
      .then((response) => {
        setFormData(response.data);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const handleBackButton = () => {
    if (hasUnsavedChangesRef.current) {
      // Show the modal if there are unsaved changes.
      setShowUnsavedChangeModal(true);
      window.history.pushState("are-you-sure", document.title, window.location.href);
    } else {
      // Go back if there are no changes.
      navigate("/clients");
    }
  };

  React.useEffect(() => {
    // Push temp state to stop back button click from navigating.
    window.history.pushState("are-you-sure", document.title, window.location.href);
    window.addEventListener("popstate", handleBackButton);

    // This return method is called when the component unmounts.
    return () => {
      // Remove the state or multiple event listeners will get stacked over time.
      window.removeEventListener("popstate", handleBackButton);
      // Remove the temporary state if it exists.
      if (window.history.state === "are-you-sure") {
        window.history.back();
      }
    };
    // eslint-disable-next-line
  }, []);

  const onChange = (tabIndex: number, fieldIndex: number, value: string | boolean): void => {
    var copy = { ...formData };
    copy.tabs[tabIndex].fields[fieldIndex].value = value;
    setFormData(copy);
    setHasUnsavedChanges(true);
    hasUnsavedChangesRef.current = true;
  };

  const onSubmitButtonClicked = () => {
    api
      .post("/thompsonaccounting/clients/new", { formData })
      .then((response) => {
        navigate("/clients");
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      });
  };

  if (isLoading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Stack direction="vertical">
        <Stack direction="vertical" style={{ width: "100%", alignItems: "center" }}>
          {errorMessage && (
            <Alert variant="danger" className="mt-3" style={{ width: "80%" }}>
              <Alert.Heading>Something's happened</Alert.Heading>
              <p>{errorMessage}</p>
            </Alert>
          )}
          <h3 style={{ width: "80%" }}>New Client Details</h3>
          <ClientDetailsTab formData={formData} onDbColChange={onChange} />
          <Button className="mx-3 my-3" style={{ width: "80%" }} variant="primary" onClick={onSubmitButtonClicked}>
            Submit
          </Button>
        </Stack>

        <ConsentModal
          show={showUnsavedChangeModal}
          title={"You have unsaved changes"}
          subtitle={"Are you sure you want to go back? Your changes will not be saved."}
          denyText={"I don't want to save"}
          deny={() => setShowUnsavedChangeModal(false)}
          acceptText={"I want to save my changes"}
          accept={() => setShowUnsavedChangeModal(false)}
        />
      </Stack>
    );
  }
};
