import React, { useCallback } from "react";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import api from "../../../api";
import { ConsentModal } from "../../../components/ConsentModal";
import { LoadingButton } from "../../../components/LoadingButton";

export interface DeleteFieldProps {
  tabNames: string[];
  onError: (message: string) => void;
}

export const DeleteField: React.FunctionComponent<DeleteFieldProps> = (props: DeleteFieldProps) => {
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [isLoadingFieldNames, setIsLoadingFieldNames] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState(props.tabNames.length > 0 ? props.tabNames[0] : "");
  const [fieldNames, setFieldNames] = React.useState([]);
  const [selectedField, setSelectedField] = React.useState("");

  const getFieldData = useCallback(
    (tabName: string) => {
      const { onError } = { ...props };
      setIsLoadingFieldNames(true);
      api
        .get(`/thompsonaccounting/field?tabName=${tabName}`)
        .then((response) => {
          if (response.data.fieldNames) {
            setFieldNames(response.data.fieldNames);
            setSelectedField(response.data.fieldNames[0]);
          }
        })
        .catch((error) => {
          onError(error.response.data.message);
        })
        .finally(() => setIsLoadingFieldNames(false));
    },
    [props]
  );

  React.useEffect(() => {
    getFieldData(selectedTab);
  }, [getFieldData, selectedTab]);

  const onSelectedTabChange = (tab: string) => {
    setSelectedField("");
    setSelectedTab(tab);
  };

  const onDeleteButtonClicked = () => {
    setShowModal(false);
    setIsDeleting(true);
    api
      .delete("/thompsonaccounting/field", { data: { tabName: selectedTab, fieldName: selectedField } })
      .then((response) => {
        // Refresh field data once it has been deleted.
        getFieldData(selectedTab);
      })
      .catch((error) => props.onError && props.onError(error.response.data.message))
      .finally(() => setIsDeleting(false));
  };

  return (
    <>
      <Form className="my-5">
        <h3>Delete Field</h3>

        <Form.Group className="mb-3">
          <Form.Label>Select the tab for the field</Form.Label>
          <Form.Select onChange={(e) => onSelectedTabChange(e.target.value)}>
            {props.tabNames.map((name, index) => {
              return <option key={`tab-name-${index}`}>{name}</option>;
            })}
          </Form.Select>
        </Form.Group>

        {isLoadingFieldNames ? (
          <div className="mb-3" style={{ height: "70px", display: "flex", alignItems: "center" }}>
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Form.Group className="mb-3">
            <Form.Label>Select the field in the tab to edit</Form.Label>
            <Form.Select onChange={(e) => setSelectedField(e.target.value)}>
              {fieldNames.map((name, index) => {
                return <option key={`field-name-${index}`}>{name}</option>;
              })}
            </Form.Select>
          </Form.Group>
        )}

        <LoadingButton isLoading={isDeleting} onClick={() => setShowModal(true)}>
          Delete field
        </LoadingButton>
      </Form>

      <ConsentModal
        show={showModal}
        title={`Delete ${selectedField}?`}
        subtitle={
          "Deleting this field will delete the data for this field for all clients. Are you sure you want to delete it?"
        }
        denyText={"Never mind"}
        deny={() => setShowModal(false)}
        acceptText={"Delete field"}
        accept={onDeleteButtonClicked}
      />
    </>
  );
};
