import React from "react";
import queryString from "query-string";
import { extractQueryParam } from "../../utils/window";

interface LogoutReasons {
  [key: number]: string;
}

export const Logout = () => {
  document.title = "Etienne Thompson - Admin Center - Logout";
  document.documentElement.className = "theme-light";

  const [reason, setReason] = React.useState(0);

  React.useEffect(() => {
    let params = queryString.parse(window.location.search);
    setReason(extractQueryParam(params, "reason"));
  }, []);

  const logoutReasons: LogoutReasons = {
    0: "You have successfully been logged out.",
    101: "Failed to authenticate. Please login again to continue.",
    102: "Failed to authenticate. Please login again to continue.",
    103: "Your session has expired. Please login again to continue.",
    104: "Failed to authenticate. Please login again to continue.",
    105: "You are not a user of this application. Please contact the system admin if you should have access.",
  };

  return <h6 style={{ width: "50%", textAlign: "center" }}>{logoutReasons[reason]}</h6>;
};
