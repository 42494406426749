import React, { ReactNode } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

interface LoadingButtonProps {
  isLoading: boolean;
  onClick: () => void;
  children?: ReactNode;
}

export const LoadingButton: React.FunctionComponent<LoadingButtonProps> = (props: LoadingButtonProps) => {
  return !props.isLoading ? (
    <Button variant="primary" onClick={props.onClick} style={{ width: "100%" }}>
      {props.children}
    </Button>
  ) : (
    <Button variant="primary" style={{ width: "100%" }}>
      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
      Loading...
    </Button>
  );
};
