import React from "react";
import { FormGroup } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import api from "../../../api";
import { LoadingButton } from "../../../components/LoadingButton";

interface MoveFieldProps {
  tabNames: string[];
  update: () => void;
  onError: (message: string) => void;
}

export const MoveField: React.FunctionComponent<MoveFieldProps> = (props: MoveFieldProps) => {
  const [isMoving, setIsMoving] = React.useState(false);
  const [isLoadingFieldNames, setIsLoadingFieldNames] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState(props.tabNames.length > 0 ? props.tabNames[0] : "");
  const [fieldNames, setFieldNames] = React.useState<string[]>([]);
  const [selectedField, setSelectedField] = React.useState("");
  const [moveToTab, setMoveToTab] = React.useState(props.tabNames.length > 0 ? props.tabNames[0] : "");

  React.useEffect(() => {
    setIsLoadingFieldNames(true);
    api
      .get(`/thompsonaccounting/field?tabName=${selectedTab}`)
      .then((response) => {
        if (response.data.fieldNames) {
          setFieldNames(response.data.fieldNames);
          setSelectedField(response.data.fieldNames[0]);
        }
      })
      .catch((error) => props.onError && props.onError(error.response.data.message))
      .finally(() => setIsLoadingFieldNames(false));
  }, [selectedTab, props]);

  const onSelectedTabChange = (tab: string) => {
    setSelectedField("");
    setSelectedTab(tab);
  };

  const onSubmitButtonClicked = () => {
    if (moveToTab === selectedTab) {
      props.onError("You must select a different tab to move the field to.");
      return;
    }

    setIsMoving(true);
    api
      .post("/thompsonaccounting/fields/move", {
        originalTabName: selectedTab,
        newTabName: moveToTab,
        fieldName: selectedField,
      })
      .then((response) => props.update && props.update())
      .catch((error) => props.onError && props.onError(error.response.data.message))
      .finally(() => setIsMoving(false));
  };

  return (
    <Form className="mt-5">
      <Row>
        <Col xs={9}>
          <h3>Move Fields</h3>
        </Col>
        <Col xs={3}>
          <LoadingButton isLoading={isMoving} onClick={onSubmitButtonClicked}>
            Submit
          </LoadingButton>
        </Col>
      </Row>

      <Form.Group className="mb-3">
        <Form.Label>Select a tab to see fields</Form.Label>
        <Form.Select onChange={(e) => onSelectedTabChange(e.target.value)}>
          {props.tabNames.map((name, index) => {
            return <option key={`tab-name-${index}`}>{name}</option>;
          })}
        </Form.Select>
      </Form.Group>

      {isLoadingFieldNames ? (
        <div className="mb-3" style={{ height: "70px", display: "flex", alignItems: "center" }}>
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <FormGroup className="mb-3">
            <Form.Label>Select the field to move</Form.Label>
            <Form.Select onChange={(e) => setSelectedField(e.target.value)}>
              {fieldNames.map((name, index) => {
                return <option key={`field-name-${index}`}>{name}</option>;
              })}
            </Form.Select>
          </FormGroup>

          <FormGroup className="mb-3">
            <Form.Label>Select the tab to move the field to</Form.Label>
            <Form.Select onChange={(e) => setMoveToTab(e.target.value)}>
              {props.tabNames.map((name, index) => {
                return <option key={`move-to-tab-${index}`}>{name}</option>;
              })}
            </Form.Select>
          </FormGroup>
        </>
      )}
    </Form>
  );
};
