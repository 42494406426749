import React from "react";
import Form from "react-bootstrap/Form";
import api from "../../../api";
import { LoadingButton } from "../../../components/LoadingButton";

export interface EditTabProps {
  tabNames: string[];
  update: () => void;
  onError: (message: string) => void;
}

export const EditTab: React.FunctionComponent<EditTabProps> = (props: EditTabProps) => {
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [currentName, setCurrentName] = React.useState(props.tabNames.length > 0 ? props.tabNames[0] : "");
  const [newName, setNewName] = React.useState(props.tabNames.length > 0 ? props.tabNames[0] : "");

  const onUpdateButtonClicked = () => {
    setIsUpdating(true);
    api
      .put("/thompsonaccounting/tabs", { currentName: currentName, newName: newName })
      .then((response) => props.update && props.update())
      .catch((error) => props.onError(error.response.data.message))
      .finally(() => setIsUpdating(false));
  };

  return (
    <Form className="mt-5">
      <h3>Edit Tab Names</h3>
      <Form.Group className="mb-3">
        <Form.Label>Select the tab to edit</Form.Label>
        <Form.Select onChange={(e) => setCurrentName(e.target.value)}>
          {props.tabNames.map((name, index) => {
            return <option key={`tab-name-${index}`}>{name}</option>;
          })}
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>The new name</Form.Label>
        <Form.Control
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
        ></Form.Control>
      </Form.Group>

      <LoadingButton isLoading={isUpdating} onClick={onUpdateButtonClicked}>
        Update tab name
      </LoadingButton>
    </Form>
  );
};
