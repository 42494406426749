export interface EditClientQueryProps {
  defaultTab?: string;
}

export const parseQueryParams = (queryParams: string): EditClientQueryProps => {
  const searchParams = new URLSearchParams(queryParams);

  const defaultTab = searchParams.get("defaultTab") ?? undefined;

  return {
    defaultTab: defaultTab,
  };
};
