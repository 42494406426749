import React from "react";
import { Accordion, Button, Col, Row, useAccordionButton } from "react-bootstrap";
import Form from "react-bootstrap/Form";

interface ClientSearchProps {
  onSearchButtonClicked: (searchData: string) => void;
}

const AccordionButton = ({ children, eventKey }: { children: React.ReactNode; eventKey: string }) => {
  const decoratedOnClick = useAccordionButton(eventKey);

  return <Button onClick={decoratedOnClick}>{children}</Button>;
};

export const ClientSearch: React.FunctionComponent<ClientSearchProps> = (props: ClientSearchProps) => {
  const [searchData, setSearchData] = React.useState("");

  return (
    <Form className="mt-4 mb-3">
      <Row className="mb-3">
        <Col sm="10">
          <Form.Control type="text" onChange={(e) => setSearchData(e.target.value)}></Form.Control>
        </Col>
        <Col sm="2">
          <Button onClick={() => props.onSearchButtonClicked(searchData)}>Search</Button>
        </Col>
      </Row>
      <Accordion>
        <AccordionButton eventKey="0">Search Explanation</AccordionButton>
        <Accordion.Collapse eventKey="0">
          <span>
            Search works in the following two formats:
            <ul>
              <li>
                <span style={{ backgroundColor: "rgba(0, 255, 0, 0.4)" }}>search value</span>
              </li>
              <li>
                <span style={{ backgroundColor: "rgba(255, 0, 0, 0.4)" }}>column name</span>
                <span style={{ backgroundColor: "rgba(0, 0, 255, 0.4)" }}> operator </span>
                <span style={{ backgroundColor: "rgba(0, 255, 0, 0.4)" }}>search value</span>
                <br />
                <div>
                  where column name is the name of the column you want to search in specific, operator is one of:
                  <ul>
                    <li>: - contains</li>
                    <li>= - equals</li>
                    <li>! - does not equal</li>
                  </ul>
                </div>
              </li>
            </ul>
            You can also specify multiple search terms at once separated by a ";".
          </span>
        </Accordion.Collapse>
      </Accordion>
    </Form>
  );
};
