import React from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import { DbCol } from "../DbCol/DbCol";
import { ClientDetailTab, ClientDetails } from "../../types/ClientDetails";

export interface ClientDetailsTabProps {
  formData: ClientDetails;
  defaultTabName?: string;
  onDbColChange: (tabIndex: number, fieldIndex: number, value: string | boolean) => void;
}

export const ClientDetailsTab: React.FunctionComponent<ClientDetailsTabProps> = (props: ClientDetailsTabProps) => {
  const defaultTabIndex = props.formData.tabs.findIndex(
    (value) => value.name === (props.defaultTabName ?? props.formData.tabs[0].name)
  );

  const renderClientDetails = (tabsData: ClientDetailTab[]) => {
    const tabContent: Map<string, React.ReactNode[]> = new Map<string, React.ReactNode[]>();
    for (const [tabIndex, tab] of tabsData.entries()) {
      tabContent.set(tabIndex.toString(), []);
      let toRenderFields: React.ReactNode[] = [];
      for (let [fieldIndex, field] of tab.fields.entries()) {
        const dependentCol = tab.fields.filter((col) => col.name === field.renderCondition?.dependentField)[0];

        if (field.renderCondition?.operator === "===" && field.renderCondition.value !== dependentCol.value) {
          continue;
        }

        toRenderFields.push(
          <DbCol tabIndex={tabIndex} fieldIndex={fieldIndex} columnData={field} onChange={props.onDbColChange} />
        );
      }

      let renderIndex = 0;
      while (renderIndex <= toRenderFields.length) {
        const row = (
          <Row key={`field-rows-${tabIndex}-${renderIndex}`}>
            <Col sm={6}>{toRenderFields[renderIndex]}</Col>
            <Col sm={6}>{renderIndex + 1 <= toRenderFields.length ? toRenderFields[renderIndex + 1] : <></>}</Col>
          </Row>
        );

        tabContent.get(tabIndex.toString())?.push(row);
        renderIndex += 2;
      }
    }

    let index = 0;
    const output: React.ReactNode[] = [];
    for (const key of tabContent.keys()) {
      output.push(
        <Tab.Pane eventKey={`form-tab-key-${index}`} key={`tab-pane-${index}`}>
          {tabContent.get(key)}
        </Tab.Pane>
      );
      index++;
    }

    return output;
  };

  return (
    <div style={{ width: "90%", display: "flex", justifyContent: "start" }}>
      <Form style={{ width: "100%" }}>
        <Tab.Container defaultActiveKey={`form-tab-key-${defaultTabIndex}`}>
          <Row>
            <Col sm={2}>
              <Nav variant="pills" className="flex-column">
                {props.formData.tabs.map((tab, i) => {
                  return (
                    <Nav.Item key={`tab-nav-item-${i}`}>
                      <Nav.Link eventKey={`form-tab-key-${i}`}>{tab.label}</Nav.Link>
                    </Nav.Item>
                  );
                })}
              </Nav>
            </Col>
            <Col sm={10}>
              <Tab.Content>{renderClientDetails(props.formData.tabs)}</Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Form>
    </div>
  );
};
